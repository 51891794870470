import Head from 'next/head';
import { useRouter } from 'next/router';

import { SeoProperties } from '../types';
import { isDevelopment } from '../utils';

const defaultLanguage: {
  postTitle: Record<string, string>;
  description: Record<string, string>;
} = {
  postTitle: {
    en: 'Create your own ZEP quiz and make your classes more fun',
    ja: '自分だけのクイズを作る',
    ko: '나만의 젭 퀴즈를 만들어 더 재미있는 수업을 진행해 보세요',
  },
  description: {
    en: 'Create a ZEP quiz for free',
    ja: '無料で製作できるZEPクイズ！',
    ko: '무료로 젭 퀴즈를 만들어 보세요',
  },
};

const getTitle = (title: string, locale: string) => {
  const postTitle =
    defaultLanguage['postTitle'][locale] || defaultLanguage['postTitle']['ko'];
  return `${title || 'ZEP QUIZ'} | ${postTitle}`;
};

export const Meta = ({ partialSeoContent }: MetaProps) => {
  const { locale = 'ko', locales = [], asPath } = useRouter();

  const defaultDescription =
    defaultLanguage['description'][locale] ??
    defaultLanguage['description']['ko'];

  const defaultImage = isDevelopment()
    ? 'https://dev-quiz.zep.us/assets/ogimage.png'
    : 'https://quiz.zep.us/assets/ogimage.png';

  const title = getTitle(partialSeoContent?.title ?? '', locale);

  const seoContent = {
    title,
    subject: partialSeoContent?.subject ?? title,
    description: partialSeoContent?.description ?? defaultDescription,
    image: partialSeoContent?.image ?? defaultImage,
    keywords:
      partialSeoContent?.keywords ??
      'ZEP, metaverse, zep quiz, meta, quiz, 젭, 젭퀴즈, 퀴즈 제작, 퀴즈 만들기, 방탈출퀴즈',
    copyright: partialSeoContent?.copyright ?? 'ZEP Inc',
    author: partialSeoContent?.author ?? '',
    schema: {
      image: defaultImage,
      ...partialSeoContent?.schema,
    } as Record<string, any>,
  };

  return (
    <Head>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1"
      />
      <title>{seoContent.title}</title>
      <meta name="title" content={seoContent.title} />
      <meta name="description" content={seoContent.description} />
      <meta name="subject" content={seoContent.subject} />
      <meta name="keywords" content={seoContent.keywords} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={seoContent.title} />
      <meta property="og:url" content={process.env.NEXT_PUBLIC_BASE_URL} />
      <meta
        property="og:title"
        content={seoContent.subject || seoContent.title}
      />
      <meta property="og:description" content={seoContent.description} />
      <meta property="og:image" content={seoContent.image} />
      <meta property="og:locale" content={locale} />
      <meta property="twitter:card" content="summary" />
      <meta property="twitter:title" content={seoContent.title} />
      <meta property="twitter:description" content={seoContent.description} />
      <meta property="twitter:image" content={seoContent.image} />
      <meta name="application-name" content="ZEP Quiz" />
      <meta name="copyright" content={seoContent.copyright} />
      <meta name="author" content={seoContent.author} />
      <meta name="reply-to" content="hello@zep.us" />
      <link
        rel="icon"
        type="image/svg+xml"
        href="/assets/ZEPQuiz-favicon.svg"
      />
      {locales
        .filter(lang => locale !== lang)
        .map(lang => (
          <link
            key={lang}
            rel="alternate"
            hrefLang={lang}
            href={`${process.env.NEXT_PUBLIC_BASE_URL}/${lang}${asPath}`}
          />
        ))}
      {isDevelopment() ? (
        <>
          <meta name="robots" content="noindex" />
          <meta name="googlebot" content="noindex" />
        </>
      ) : (
        <meta name="robots" content="index, follow" />
      )}
      {seoContent.schema['@context'] && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(seoContent.schema),
          }}
        />
      )}
    </Head>
  );
};

type MetaProps = {
  partialSeoContent: Partial<SeoProperties>;
};
