// eslint-disable-next-line simple-import-sort/imports
import '../styles/globals.scss';

import { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import ReactModal from 'react-modal';
import NiceModal from '@ebay/nice-modal-react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import {
  HydrationBoundary,
  QueryCache,
  QueryClient,
  QueryClientProvider,
  QueryErrorResetBoundary,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { isClient } from '@toss/utils';
import { getRootLayout } from '@zep/layout';
import { UserProvider } from '@zep/lib/auth';
import { NextPageWithLayout } from 'next';
import { AppProps } from 'next/app';
import { appWithTranslation } from 'next-i18next';

import '../init.ts';

import GlobalErrorBoundary from '../components/GlobalErrorBoundary';
import { Meta } from '../components/Meta';
import { useAnalytics, useRouteChangeTracker } from '../hooks';
import { useApiError } from '../hooks/useApiError.ts';

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function CustomApp({ Component, pageProps }: AppPropsWithLayout) {
  const handleError = useApiError();
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          mutations: {
            onError: handleError,
            throwOnError: false,
          },
          queries: {
            throwOnError: true,
            retry: false,
            staleTime: 1000 * 60 * 3,
          },
        },
        queryCache: new QueryCache({
          onError: handleError,
        }),
      }),
  );

  useRouteChangeTracker();
  useAnalytics();

  useEffect(() => {
    if (isClient()) {
      ReactModal.setAppElement('#root');
    }
  }, []);

  const getLayout = Component.getLayout ?? getRootLayout;

  return (
    <QueryErrorResetBoundary>
      <GlobalErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <UserProvider>
            <NiceModal.Provider>
              <HydrationBoundary state={pageProps.dehydratedState}>
                <Meta partialSeoContent={pageProps.seoContent} />
                <GoogleOAuthProvider
                  clientId={process.env.NEXT_PUBLIC_GOOGLE_OAUTH_ID || ''}>
                  <div id="root">{getLayout(<Component {...pageProps} />)}</div>
                </GoogleOAuthProvider>
                <Toaster position={'top-right'} />
                <ReactQueryDevtools
                  buttonPosition={'bottom-left'}
                  initialIsOpen={false}
                />
              </HydrationBoundary>
            </NiceModal.Provider>
          </UserProvider>
        </QueryClientProvider>
      </GlobalErrorBoundary>
    </QueryErrorResetBoundary>
  );
}

export default appWithTranslation(CustomApp);
