import { createSuspenseQuery } from 'react-query-kit';
import { mapValues } from '@toss/utils';
import { SupportedLocale } from '@zep/consts/localize.ts';
import { apiClient } from '@zep/utils/api.ts';
import { groupBy } from 'lodash-es';
import { MapTemplate, MapTemplateLocalize } from 'src/types';

export const useSuspenseTemplates = createSuspenseQuery<{
  status: string;
  data: MapTemplate[];
}>({
  queryKey: ['mapTemplate'],
  fetcher: async () => {
    const res = await apiClient.get<{ status: string; data: MapTemplate[] }>(
      '/template/all',
      {},
    );

    // serializeLocalize 데이터를 만듭니다
    const data = res.data.data?.map(d => {
      const _localizeData = d.localizeData.concat({
        description: d.description,
        name: d.name,
        previewPlayUrl: d.previewPlayUrl,
        type: d.type,
        locale: 'ko',
      });

      return {
        ...d,
        serializedLocalize: mapValues(
          groupBy<MapTemplateLocalize>(_localizeData, 'locale'),
          list => list[0] || {},
        ) as Record<SupportedLocale, MapTemplateLocalize>,
      };
    });

    return { status: res.data.status, data };
  },
});
