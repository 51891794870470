import { useIsMounted } from '@toss/react';
import { useSupportedLocale } from '@zep/hooks/useSupportedLocale.ts';
import { formatDistanceToNow } from 'date-fns';
import { enUS, ja, ko } from 'date-fns/locale';

/**
 * 현재 시간으로부터 지난 시간을 표시하는 훅
 * 1일전, 1달전, 1년전..
 * */
export function useDistanceToNow() {
  const _locale = useSupportedLocale();
  const isMounted = useIsMounted();

  return (date: string) => {
    if (!isMounted) return '';
    return formatDistanceToNow(date, {
      locale: _locale === 'ko' ? ko : _locale === 'ja' ? ja : enUS,
      addSuffix: true,
    });
  };
}
