import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useIsMounted } from '@toss/react';

import { zepAnalytics } from '../utils/analytics';

export const useAnalytics = () => {
  const isMounted = useIsMounted();

  useEffect(() => {
    if (isMounted) {
      zepAnalytics.setDefaultUserProperties();
    }
  }, [isMounted]);

  useEffect(() => {
    ReactGA.initialize([
      {
        trackingId: `${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_TRACKING_ID}`,
      },
    ]);

    ReactGA.gtag('config', process.env.NEXT_PUBLIC_GOOGLE_ADS_TRACKING_ID);
    ReactGA.event('conversion', {
      send_to: 'AW-11409990767/ZwXSCJfk9qEZEO_I2sAq',
    });
  }, []);
};
