import { createSuspenseQuery } from 'react-query-kit';
import { ApiRoute } from '@zep/api/apiRoute.const.ts';
import { apiClient } from '@zep/utils/api.ts';

type FetchIsoResponse = {
  data: { isoCode: string; continentName: string; countryName: string };
  status: string;
};

const fetchIso = () =>
  apiClient.get<FetchIsoResponse>(ApiRoute.API_ISO).then(res => res.data);

export const useSuspenseISO = createSuspenseQuery<FetchIsoResponse>({
  queryKey: ['iso'],
  fetcher: fetchIso,
});
