import ReactGA from 'react-ga4';

export * from './type.ts';
export * from './getDeviceInformation';
export * from './zepEventTracker';

export const sendPageView = (page: string) => {
  ReactGA.set({ page });
  ReactGA.send('pageview');
};

export * from './zepAnalytics';
